// https://medium.com/@san.vuthy08/html-react-parser-1d0df932303a

import React from "react";
import { graphql } from "gatsby";
import useUser from "../lib/auth";
import parse from "html-react-parser";
// import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
// import { nord as style } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Box, Container } from "@chakra-ui/react";
import Layout from "../layouts/Layout";

// function replaceCode(node) {
//   if (node.attribs && node.attribs["data-language"]) {
//     return (
//       node.children.length > 0 && (
//         <Code language={getLanguage(node)}>
//           {domToReact(getCode(node.children[0]))}
//         </Code>
//       )
//     );
//   }
// }

// function getLanguage(node) {
//   if (node.attribs && node.attribs["data-language"] != null) {
//     return node.attribs["data-language"];
//   }
//   return null;
// }

// function getCode(node) {
//   if (node.children.length > 0 && node.children[0].name === "code") {
//     return node.children[0].children;
//   } else {
//     return node.children;
//   }
// }

// const Code = ({ language, children }) => (
//   <SyntaxHighlighter style={style} language={language}>
//     {children}
//   </SyntaxHighlighter>
// );

export const StyleGuidePageQuery = graphql`
  query {
    heroBg: file(relativePath: { eq: "hero-bg-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
      extension
      publicURL
    }
    markdownRemark(frontmatter: { slug: { eq: "/style-guide/" } }) {
      frontmatter {
        title
      }
      html
    }
  }
`;

function StyleGuidePage({ data }) {
  const { loading, isAuthenticated } = useUser({ required: true });
  if (loading || !isAuthenticated) return null;

  const { frontmatter, html } = data.markdownRemark;
  if (!frontmatter || !html) return null;

  return (
    <Layout pageTitle="Style Guide" backgroundImage={data.heroBg}>
      <Box padding="80px 30px">
        <Container maxW="container.md">
          <div class="content">
            {parse(html)}
            {/* {parse(html, { replace: replaceCode })} */}
          </div>
        </Container>
      </Box>
    </Layout>
  );
}

export default StyleGuidePage;
